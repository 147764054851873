import React from 'react';

const Register = () => {
    return (
        <div style={styles.container}>
            <h2>Register</h2>
            <p>Create an account to get started.</p>
            <button style={styles.button}>Register</button>
        </div>
    );
};

// Stilizacija
const styles = {
    container: {
        textAlign: 'center',
        padding: '20px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
    }
};

export default Register;
