import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';

const ImageSlider = () => {
    const [sliderData, setSliderData] = useState([]);
    const [translations, setTranslations] = useState({});
    const [language, setLanguage] = useState('en');
    const [loading, setLoading] = useState(true); // Added loading state for data fetching

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const languageFromUrl = urlParams.get('language');

        if (languageFromUrl) {
            setLanguage(languageFromUrl.toLowerCase());
        }
    }, [language]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 300, // Reduced speed to make it faster
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: 'slick-dots-custom',
        lazyLoad: 'ondemand', // Enable lazy loading for images
    };

    // Fetch translations
    useEffect(() => {
        axios.get(`https://loyalty.ranc-ramarin.hr/api/translations/${language}`)
            .then(response => {
                setTranslations(response.data);
            })
            .catch(error => {
                console.error('Error fetching translations:', error);
            });
    }, [language]);

    // Fetch slider data
    useEffect(() => {
        axios.get(`https://loyalty.ranc-ramarin.hr/api/slider-data/${language}`)
            .then(response => {
                setSliderData(response.data.slider);
                setLoading(false);  // Set loading to false once data is fetched
            })
            .catch(error => {
                console.error('Error fetching slider data:', error);
                setLoading(false);  // Set loading to false if there's an error
            });
    }, [language]);

    return (
        <div style={{
            position: 'relative',
            maxWidth: '100%',
            margin: '0 auto',
            overflow: 'hidden'
        }}>
            {/* Loading Modal */}
            {loading && (
                <div style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                    }}>

                    </div>
                </div>
            )}

            {sliderData.length > 0 ? (
                <Slider {...settings}>
                    {sliderData.map((slide, index) => (
                        <div key={index}>
                            <div style={{
                                position: 'relative',
                                backgroundColor: '#3E2723'
                            }}>
                                <img
                                    src={slide.image}
                                    alt={`Slide ${index + 1}`}
                                    style={{
                                        width: '80%',
                                        marginLeft: '10%',
                                        height: 'auto',
                                        zIndex: 1,
                                        display: 'block',
                                    }}
                                    loading="lazy" // Lazy loading images
                                />
                                <img
                                    src="https://loyalty.ranc-ramarin.hr/data/source/slider/Subtract.png"
                                    alt="Bottom Image"
                                    style={{
                                        position: 'absolute',
                                        bottom: -100,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        width: '100%',
                                        maxWidth: '100%',
                                        zIndex: 10,
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '70%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'black',
                                        textAlign: 'center',
                                        padding: '10px',
                                        zIndex: 20,
                                        width: '80%',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <h2>{slide.title}</h2>
                                    <p>{slide.description}</p>
                                </div>

                                <div style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    display: 'flex',
                                    gap: '20px',
                                    zIndex: 100
                                }}>
                                    <button style={{
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: 0,
                                        position: 'relative',
                                        width: '150px',
                                        height: 'auto'
                                    }}>
                                        <img
                                            src="https://loyalty.ranc-ramarin.hr/data/source///graphics/preskoci.png"
                                            alt={translations.skip || 'Skip'}
                                            style={{ width: '150px', height: 'auto' }}
                                        />
                                        <span style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            color: '#3E2723',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}>
                                            {translations.dalje}
                                        </span>
                                    </button>

                                    <button
                                        onClick={() => {
                                            window.location.href = `/?page=loginIndex&language=${language}`;
                                        }}
                                        style={{
                                            background: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: 0,
                                            position: 'relative',
                                            width: '150px',
                                            height: 'auto',
                                        }}>
                                        <img
                                            src="https://loyalty.ranc-ramarin.hr/data/source//graphics/kreni.png"
                                            alt={translations.next || 'Start'}
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                color: 'white',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}>
                                            {translations.preskoci}
                                        </span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                <p></p>
            )}

            <style jsx>{`
                .slick-dots-custom {
                    position: absolute;
                    bottom: 100px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 80;
                    display: flex !important;
                    flex-direction: row !important;
                    justify-content: center !important;
                    align-items: center !important;
                    padding: 0 !important;
                    margin: 0 !important;
                    flex-wrap: nowrap !important;
                }

                .slick-dots-custom li {
                    margin: 0 8px !important;
                    list-style: none !important;
                }

                .slick-dots-custom li button {
                    background: transparent !important;
                    width: 8px !important;
                    height: 8px !important;
                    padding: 0 !important;
                    border: none !important;
                    cursor: pointer !important;
                    background-image: url('https://loyalty.ranc-ramarin.hr/data/source/graphics/dot.png') !important;
                    background-size: cover !important;
                    background-repeat: no-repeat !important;
                    text-indent: -9999px !important;
                }

                .slick-dots-custom li.slick-active button {
                    width: 32px !important;
                    height: 8px !important;
                    background-image: url('https://loyalty.ranc-ramarin.hr/data/source//graphics/dotActive.png') !important;
                }
            `}</style>
        </div>
    );
};

export default ImageSlider;
