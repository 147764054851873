import React, { useState, useEffect } from 'react';
import ImageSlider from './components/slider';
import LoginIndex from './components/login';
import LoginLogin from './components/login/login';
import LoginRegister from './components/login/register';

const App = () => {
    const [currentComponent, setCurrentComponent] = useState(null);

    // Objekt koji mapira nazive stranica na komponente
    const components = {
        'slider': ImageSlider,
        'loginIndex': LoginIndex,
        'loginLogin': LoginLogin,
        'loginRegister': LoginRegister,
    };

    useEffect(() => {
        // Dohvati 'page' parametar iz URL-a
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');

        // Ako postoji page parametar i odgovarajuća komponenta, postavi je
        if (page && components[page]) {
            setCurrentComponent(() => components[page]);
        } else {
            // Default komponenta ako page nije definiran ili ne postoji
            setCurrentComponent(() => ImageSlider);
        }
    }, []); // Prazan dependency array jer želimo da se izvrši samo pri mountu

    // Dinamički renderiraj komponentu
    const ComponentToRender = currentComponent;

    return (
        <div>
            <style>
                {`
                    @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');
                    body {
                        font-family: 'Urbanist', sans-serif;
                    }

             
                    html, body {
                        touch-action: manipulation; 
                        user-select: none; 
                        -webkit-user-select: none;
                        font-size: 16px;  /* Postavi font na odgovarajuću veličinu kako bi spriječio automatsko zumiranje */
                    }

                    input, textarea, select {
                        font-size: 16px; /* Postavi font-size za inpute */
                    }
                `}
            </style>

            {ComponentToRender ? <ComponentToRender/> : <p>Loading...</p>}
        </div>
    );
};

export default App;
