import React, {useEffect, useState} from 'react';
import logo from '../../assets/images/LogoLogin.png'; // Importiranje slike
import buttonSmedji from '../../assets/images/buttonSmedji.png'; // Importiranje slike za prvi gumb
import buttonLight from '../../assets/images/buttonLight.png';
import axios from "axios"; // Importiranje slike za drugi gumb

const Login = () => {
    const [translations, setTranslations] = useState({});
    const [language, setLanguage] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const languageFromUrl = urlParams.get('language');



        if (languageFromUrl) {
            setLanguage(languageFromUrl.toLowerCase());
        }
        console.log(language)

        axios.get(`https://loyalty.ranc-ramarin.hr/api/translations/${language}`)
            .then(response => {
                setTranslations(response.data);
            })
            .catch(error => {
                console.error('Error fetching translations:', error);
            });
    }, [language]);


    return (
        <div style={styles.container}>
            {/* Header s logotipom */}
            <header style={styles.header}>
                <img
                    src={logo}  // Koristi uvezeni logo
                    alt="Logo"
                    style={styles.logo}
                />
            </header>

            {/* Novi div za centriranje i grupiranje tekstova */}
            <div style={styles.textContainer}>
                {/* Dobrodošli tekst */}
                <h2 style={styles.welcomeText}>{translations.loginDobrodosli}</h2>
                {/* Nekoliko koraka do računa tekst */}
                <p style={styles.subText}>{translations.loginKoraci}</p>
            </div>

            {/* Novi kontejner za button-e */}
            <div style={styles.buttonContainer}>
                {/* Prvi button */}
                <button style={styles.button}>
                    <a href={`?page=loginRegister&language=${language}`} style={styles.buttonLink}>
                        <img src={buttonSmedji} alt="Button Smedji" style={styles.buttonImage}/>
                        <span style={styles.buttonText1}>{translations.loginRegister}</span>
                    </a>
                </button>

                <button style={styles.button}>
                    <a href={`?page=loginLogin&language=${language}`} style={styles.buttonLink}>
                        <img src={buttonLight} alt="Button Light" style={styles.buttonImage}/>
                        <span style={styles.buttonText}>{translations.loginRacun}</span>
                    </a>
                </button>

            </div>
        </div>
    );
};

// Stilizacija
const styles = {
    container: {
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#efefef',
        height: '100vh',  // Koristi 100vh da zauzme cijeli ekran
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: '80px',
    },
    logo: {
        width: '205px',   // Širina slike
        height: '77px',   // Visina slike
    },
    textContainer: {
        display: 'flex',  // Koristimo flexbox za centriranje
        flexDirection: 'column',  // Vertikalno poredanje
        justifyContent: 'center',  // Vertikalno centriranje
        alignItems: 'center',  // Horizontalno centriranje
        marginTop: '60px',  // Razmak od logotipa
    },
    welcomeText: {
        fontWeight: 'bold',  // Bold font
        fontSize: '32px',    // Veličina fonta 32px
        margin: '20px 0',    // Malo razmaka iznad i ispod
    },
    subText: {
        fontSize: '18px',    // Veličina fonta 18px
        fontWeight: 'normal', // Regularan font
        margin: '0',         // Nema razmaka
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',  // Vertikalno poredanje button-a
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '100px',  // Razmak od prethodnog dijela
    },
    button: {
        border: 'none',
        padding: '0',
        marginBottom: '20px',  // Razmak između button-a
        width: '382px',   // Dimenzije gumba
        height: '58px',
        fontSize: '18px',  // Veličina fonta
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',  // Centriranje sadržaja unutar gumba
        alignItems: 'center',  // Centriranje sadržaja unutar gumba
        position: 'relative',  // Za sliku unutar gumba
    },
    buttonImage: {
        width: '100%',  // Prilagođeno na širinu gumba
        height: '100%',  // Prilagođeno na visinu gumba
        position: 'absolute',  // Slika je pozicionirana iza teksta
        top: '0',
        left: '0',
    },
    buttonText: {
        zIndex: 1,  // Osiguravamo da tekst bude iznad slike
        color: 'black',  // Boja teksta (mijenjati po potrebi)
    },
    buttonText1: {
        zIndex: 1,  // Osiguravamo da tekst bude iznad slike
        color: 'white',  // Boja teksta (mijenjati po potrebi)
    },
    buttonLink: {
        textDecoration: 'none', // Uklanja podcrtavanje
        color: 'inherit',      // Očuvava boju teksta iz gumba
        display: 'flex',       // Održava layout gumba
        justifyContent: 'center',
        alignItems: 'center',
    }

};

export default Login;
