import React, { useEffect, useState, useRef } from 'react';
import logo from '../../assets/images/LogoLogin.png';
import buttonSmedji from '../../assets/images/buttonSmedji.png';
import horseImage from '../../assets/icons/horse.png';
import lockImage from '../../assets/icons/lock.png';
import messageImage from '../../assets/icons/Message.png';
import hideImage from '../../assets/icons/Hide.png';
import axios from "axios";

const Login = () => {
    const [translations, setTranslations] = useState({});
    const [language, setLanguage] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const emailRef = useRef(''); // Koristi useRef za email
    const passwordRef = useRef(''); // Koristi useRef za password
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const languageFromUrl = urlParams.get('language');
        if (languageFromUrl) {
            setLanguage(languageFromUrl.toLowerCase());
        }
    }, [language]);

    useEffect(() => {
        if (language) {
            axios.get(`https://loyalty.ranc-ramarin.hr/api/translations/${language}`)
                .then(response => {
                    setTranslations(response.data);
                })
                .catch(error => {
                    console.error('Error fetching translations:', error);
                });
        }
    }, [language]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleLogin = async () => {
        setLoading(true);

        try {
            const response = await axios.post('https://loyalty.ranc-ramarin.hr/api/login', {
                email: emailRef.current,
                password: passwordRef.current,
            });

            if (response.data.userToken) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({ type: 'login_success', token: response.data.userToken })
                );
            } else {
                console.error('No token received');
                setErrorMessage(translations.LoginError || 'Login failed - no token');
            }
        } catch (error) {
            console.error('Login error:', error.response?.data || error.message);
            setErrorMessage(
                error.response?.status === 401
                    ? translations.LoginRegister15
                    : translations.LoginRegister16
            );
        } finally {
            setLoading(false);
        }
    };


    return (
        <div style={styles.container}>
            {/* Modal */}
            {loading && (
                <div style={styles.modalContainer}>
                    <div style={styles.modal}>
                        <p style={styles.modalText}>Prijava u tijeku...</p>
                        <div style={styles.spinner}></div>
                    </div>
                </div>
            )}

            {/* Modal za grešku */}
            {errorMessage && (
                <div style={styles.modalContainer}>
                    <div style={styles.modal}>
                        <p style={styles.modalText}>{errorMessage}</p>
                        <button onClick={() => setErrorMessage('')} style={styles.closeButton}>
                            Zatvori
                        </button>
                    </div>
                </div>
            )}

            <div style={styles.textContainer3}>
                <h2 style={styles.text1}>{translations.LoginRegister1}</h2>
                <img src={horseImage} alt="Horse" style={styles.horseImage} />
            </div>

            <div style={styles.textContainer}>
                <p style={styles.text2}>{translations.LoginRegister2}</p>
            </div>

            <div style={styles.textContainer2}>
                <p style={{ ...styles.text2, fontWeight: 'bold' }}>{translations.LoginRegister3}</p>
            </div>

            <div style={styles.formContainer}>
                <div style={{ position: 'relative' }}>
                    <input
                        type="email"
                        name="email"
                        placeholder={translations.LoginRegister4}
                        style={styles.input}
                        defaultValue={emailRef.current}
                        onChange={(e) => emailRef.current = e.target.value} // Umjesto useState
                        autoFocus={false}
                    />
                    <img
                        src={messageImage}
                        alt="Lock Icon"
                        style={{
                            position: 'absolute',
                            left: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: '20px',
                            height: '20px',
                        }}
                    />
                </div>
            </div>

            <div style={styles.textContainer2}>
                <p style={{ ...styles.text2, fontWeight: 'bold' }}>{translations.LoginRegister5}</p>
            </div>

            <div style={styles.formContainer}>
                <div style={{ position: 'relative' }}>
                    <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        placeholder={translations.LoginRegister6}
                        style={styles.input}
                        defaultValue={passwordRef.current}
                        onChange={(e) => passwordRef.current = e.target.value} // Umjesto useState
                        autoFocus={false}
                    />
                    <img
                        src={lockImage}
                        alt="Lock Icon"
                        style={{
                            position: 'absolute',
                            left: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: '20px',
                            height: '20px',
                        }}
                    />

                    <img
                        src={hideImage}
                        alt="Hide Icon"
                        onClick={togglePasswordVisibility} // Dodajte onClick funkciju za mijenjanje vidljivosti
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer', // Dodajte kursorski pokazivač za ikonu
                        }}
                    />
                </div>
            </div>

            <div style={styles.textContainer2}>
                <p style={styles.text2}>{translations.LoginRegister14}</p>
            </div>

            <button style={styles.button} onClick={handleLogin}>
                <img src={buttonSmedji} alt="Button Smedji" />
                <span style={styles.buttonText1}>{translations.LoginRegister11}</span>
            </button>
        </div>
    );
};

// Stilizacija
const styles = {
    container: {
        textAlign: 'left',
        padding: '20px',
        height: '100vh',
        backgroundColor: 'white',
    },
    modalContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparentna crna pozadina
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        zIndex: 10000,
        width: '80%', // Širina modala
    },
    modalText: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    closeButton: {
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#3498db',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        borderRadius: '5px',
    },
    spinner: {
        marginTop: '20px',
        width: '40px',
        height: '40px',
        border: '4px solid #f3f3f3',
        borderTop: '4px solid #3498db',
        borderRadius: '50%',
        animation: 'spin 2s linear infinite',
    },

    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        marginTop: '10px',
    },

    textContainer2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        marginTop: '30px',
    },

    textContainer3: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
    },

    text1: {
        fontWeight: 'bold',
        fontSize: '32px',
        margin: '20px 0',
    },

    text2: {
        fontSize: '18px',
        fontWeight: 'normal',
        margin: '0',
    },

    input: {
        width: '90%',
        padding: '5px 5px 5px 40px',
        marginTop: '5px',
        marginBottom: '5px',
        fontSize: '11px',
        border: 'none',
        backgroundColor: '#FAFAFA',
    },

    button: {
        border: 'none',
        background: 'transparent',
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: '200px',
        height: '50px',
        position: 'relative',
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    buttonText1: {
        position: 'absolute',
        color: 'white',
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        zIndex: 1,
    },
};

export default Login;
